import axios from 'axios';
import { scopeUserRead } from '@mfe/react-asulado-msal';
import { encryptData, decryptData } from './functions/encryption';

export * from './functions';
export * from 'valtio';
export * from 'file-saver';
export { default as moment } from 'moment';
export { default as lodash } from 'lodash';
export { default as es } from 'dayjs/locale/es';
export { travelStore, errorApiStore } from './stores';

export const API = {
  catalogo: process.env.REACT_APP_PUBLIC_API_URL_CATALOGO,
  simulacion: process.env.REACT_APP_PUBLIC_API_URL_SIMULACION
}

const defaultHeaders = {
  Accept: "*/*",
  "Content-Type": "application/json",
};

export const fetchTokenApi = async (instance, accounts) => {
  await instance.initialize();
  /*const response = await instance.acquireTokenSilent({
    scopes: scopeUserRead,
    account: accounts[0],
  })*/

  const accessToken=""
  
  return accessToken
}

export const encryptDataUtil = (input) => {
  const encrypted = encryptData(input)
  return encrypted
}

export const decryptDataUtil = (input) => {
  const decrypted = decryptData(input)
  return decrypted
}


//Llamado a servicios
export const getService = async (endpoint, customHeaders = {}) => {
  try {
    const response = await axios.get(endpoint, {
      headers: { ...defaultHeaders, ...customHeaders }
    });
    return response.data.serviceResponse
  } catch (error) {
    return error.response ? error.response.status : 'Network Error'
  }
};

export const postService = async (endpoint, body, customHeaders = {}) => {
  try {
    const response = await axios.post(endpoint, body, {
      headers: { ...defaultHeaders, ...customHeaders }
    });
    return response.data.serviceResponse
  } catch (error) {
    return error.response ? error.response.status : 'Network Error'
  }
};

export const putService = async (endpoint, body, customHeaders = {}) => {
  try {
    const response = await axios.put(endpoint, body, {
      headers: { ...defaultHeaders, ...customHeaders }
    });
    return response.data.serviceResponse;
  } catch (error) {
    return error.response ? error.response.status : 'Network Error';
  }
};

export const deleteService = async (endpoint, body = {}, customHeaders = {}) => {
  try {
    const response = await axios.delete(endpoint, {
      data: body,
      headers: { ...defaultHeaders, ...customHeaders }
    });
    return response.data.serviceResponse;
  } catch (error) {
    return error.response ? error.response.status : 'Network Error';
  }
};