import { startCase, camelCase } from "lodash";
import {
  fetchTokenApi,
} from "../mfe-ts-asulado-utilitaries";
import { navigateToUrl } from "single-spa";
import { navigateToNextRoute } from "@mfe/js-asulado-uiutils";
import { errorApiStore } from "../stores/errorApi-store";

const handleApiError = (error) => {
  if (
    (error.response && error?.response?.status >= 500) ||
    error.code === "ERR_NETWORK" ||
    error.code === "ERR_NETWORK_CHANGED"
  ) {
    errorApiStore.isError = true;
  } else if (String(error).includes("BrowserAuthError")) {
    errorApiStore.isError = true;
    errorApiStore.requestErrorMessage =
      "Su sesión ha caducado, vuelva a iniciarla oprimiendo el botón de iniciar sesión.";
    errorApiStore.buttonMessage = "Iniciar Sesión";
    localStorage.clear();
    sessionStorage.clear();
  }
};

export const getAccessTokenApi = async (
  instance,
  accounts,
  setData
) => {
  try {
    const response = await fetchTokenApi(instance, accounts);
    setData(response);
  } catch (error) {
    handleApiError(error);
  }
};

