import CryptoJS from 'crypto-js';

const secretKey = 'my-secret-key'; 


export function encryptData(data: string): string {
    const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
    return ciphertext;
}


export function decryptData(ciphertext: string): string {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}